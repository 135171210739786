import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const CodeOfEthicsPage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>
            PJK MARKETING LTD Accessibility Statement
          </SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              <span>Last Updated: September 20, 2023</span>
            </p>
            <p>
              <h2>Our Commitment to Accessibility</h2>
            </p>
            <p>
              <span>PJK MARKETING LTD is committed to making our website,</span>
              <a
                href="https://ai-productreviews.com/"
                target="_blank"
                rel="noopener"
              >
                <span> </span>
                https://ai-productreviews.com/
              </a>
              <span>
                , accessible to all visitors, including those with disabilities.
                We strive to provide a user-friendly experience for everyone,
                regardless of their abilities or impairments.
              </span>
            </p>
            <p>
              <h2>Accessibility Features</h2>
            </p>
            <p>
              <span>
                We have implemented the following accessibility features to
                improve the usability of our website:
              </span>
            </p>
            <ul>
              <li>
                <span>
                  Alternative Text: We provide alternative text for all
                  meaningful images and graphics on our website to ensure that
                  screen readers can convey this information to visually
                  impaired users.
                </span>
              </li>
              <li>
                <span>
                  Keyboard Navigation: Our website is designed to be navigable
                  using only a keyboard, allowing users who rely on keyboard
                  navigation to access all content and features.
                </span>
              </li>
              <li>
                <span>
                  Readable Text: We use legible fonts and provide sufficient
                  contrast between text and background colors to enhance
                  readability for all users, including those with visual
                  impairments.
                </span>
              </li>
              <li>
                <span>
                  Resizable Text: Our website supports text resizing so that
                  users can adjust the font size to their preference through
                  browser settings.
                </span>
              </li>
              <li>
                <span>
                  Video Captions: Whenever possible, we include captions or
                  transcripts for multimedia content to make it accessible to
                  users with hearing impairments.
                </span>
              </li>
            </ul>
            <p>
              <h2>Accessibility Assistance</h2>
            </p>
            <p>
              <span>
                If you encounter any accessibility issues while using our
                website or have suggestions on how we can improve accessibility,
                please let us know. We are committed to addressing and resolving
                any accessibility barriers promptly.
              </span>
            </p>
            <p>
              <h2>Contact Information</h2>
            </p>
            <p>
              <span>
                For accessibility-related inquiries or concerns, please contact
                us at:
              </span>
            </p>
            <p>
              <span>PJK MARKETING LTD</span>
              <span>Hope Cottage Draycott, Claverley,</span>
              <span>Wolverhampton, Shropshire,</span>
              <span>England, WV5 7EA</span>
              <span>Email: info@ai-productreviews.com</span>
            </p>
            <p>
              <h2>Feedback</h2>
            </p>
            <p>
              <span>
                We welcome feedback from our users to help us enhance the
                accessibility of our website continuously. Your input is
                valuable and will contribute to a better online experience for
                all visitors.
              </span>
            </p>
            <p>
              <span>Date of Statement: September 20, 2023</span>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default CodeOfEthicsPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Accessibility | AI Product Reviews"}
      description={
        "Ensuring AI reviews are accessible to all users. Discover our commitment to inclusivity by reading our accessiblity page."
      }
      pathname={location.pathname}
    />
  );
};
